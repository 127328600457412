//
// Table
//

// Table
.table {

  // Fix for BS 5.1.2 update
  &> :not(:first-child) {
    border-color: transparent;
    border-width: 0;
    border-style: none;
  }

  > :not(:last-child)> :last-child>* {
    border-bottom-color: inherit;
  }

  thead {
    background-color: lighten($primary, 50%);
  }

  tr,
  th,
  td {
    border-color: inherit;
    border-width: 0;
    border-style: inherit;
    text-transform: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    height: inherit;
    min-height: inherit;

    &:first-child {
      padding-left: 10;
      align-self: center;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  tfoot,
  tbody {
    tr:last-child {
      border-bottom: 0 !important;

      th,
      td {
        border-bottom: 0 !important;
      }
    }
  }

  tfoot {

    th,
    td {
      border-top: inherit;
    }
  }

  // Rounded
  &.table-rounded {
    border-radius: $border-radius;
    border-spacing: 0;
    border-collapse: separate;
  }

  // Flush
  &.table-flush {

    tr,
    th,
    td {
      padding: inherit;
    }
  }

  // Row bordered
  &.table-row-bordered {
    tr {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: $border-color;
    }

    tfoot {

      th,
      td {
        border-top-width: 1px !important;
      }
    }
  }

  // Row dashed
  &.table-row-dashed {
    tr {
      border-bottom-width: 1px;
      border-bottom-style: dashed;
      border-bottom-color: $border-color;
    }

    tfoot {

      th,
      td {
        border-top-width: 1px !important;
      }
    }
  }

  // Row border colors
  @each $color,
  $value in $grays {
    &.table-row-gray-#{$color} {
      tr {
        border-bottom-color: $value;
      }
    }
  }

  //Resizing
  .resizer {
    display: inline-block;
    background: #454679;
    width: 3px;
    height: 100%;
    position: absolute;
    right: 0;

    top: 0;
    transform: translateX(50%);
    z-index: 999;
    touch-action: none;

    &.isResizing {
      background: red;
    }
  }

  //Sticky
  &.sticky {
    overflow: scroll;

    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }
}

// Sorting
.table-sort {
  &:after {
    opacity: 0;
  }
}

.table-sort,
.table-sort-asc,
.table-sort-desc {
  vertical-align: middle;

  &:after {
    position: relative;
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    content: ' ';
    bottom: auto;
    right: auto;
    left: auto;
    margin-left: 0.5rem;
  }
}

.table-sort-asc {
  &:after {
    opacity: 1;
    @include svg-bg-icon(arrow-top, $text-muted);
  }
}

.table-sort-desc {
  &:after {
    opacity: 1;
    @include svg-bg-icon(arrow-bottom, $text-muted);
  }
}

// Loading
.table-loading-message {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  @include border-radius($border-radius);
  box-shadow: $dropdown-box-shadow;
  background-color: $tooltip-bg;
  color: if(isDarkMode(), $gray-700, $gray-600);
  font-weight: $font-weight-bold;
  margin: 0 !important;
  width: auto;
  padding: 1rem 2rem !important;
  transform: translateX(-50%) translateY(-50%);
}

.table-loading {
  position: relative;

  .table-loading-message {
    display: block;
  }
}

// Cell gutters
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name,
    $value in $gutters {
      .table.g#{$infix}-#{$name} {

        th,
        td {
          padding: $value;

          // Datatables responsive mode fix
          &.dtr-control {
            padding-left: $value  !important;
          }
        }
      }

      .table.gy#{$infix}-#{$name} {

        th,
        td {
          padding-top: $value;
          padding-bottom: $value;
        }
      }

      .table.gx#{$infix}-#{$name} {

        th,
        td {
          padding-left: $value;
          padding-right: $value;

          // Datatables responsive mode fix
          &.dtr-control {
            padding-left: $value  !important;
          }
        }
      }

      .table.gs#{$infix}-#{$name} {

        th,
        td {
          &:first-child {
            padding-left: $value;
          }

          &:last-child {
            padding-right: $value;
          }

          // Datatables responsive mode fix
          &.dtr-control:first-child {
            padding-left: $value  !important;
          }
        }
      }
    }
  }
}